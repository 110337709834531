export default defineNuxtRouteMiddleware((to) => {
  const { isDesktop } = useDevice();

  const id = to.params.id;

  if (isDesktop) {
    if (["ai-girlfriend-chat-id", "ai-girlfriend-profile-id"].includes(to.name as string) && id) {
      return navigateTo(`/ai-girlfriend/${id}`);
    }
  }
});
